export const lisings = [
    {
        name: 'Parking Close To University of Waterloo/Laurier Available!',
        description: 'K-W Property Management Corp Presents:   Located at 330 Phillip St ICON Waterloo! Parking Spot for Rent Available Only for Residents of the Building Close To University',
        price: '150',
        img: 'https://plus.unsplash.com/premium_photo-1675756583871-6be3905c4ef4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
    },
    {
        name: 'Parking Close To University of Waterloo/Laurier Available!',
        description: 'K-W Property Management Corp Presents:   Located at 330 Phillip St ICON Waterloo! Parking Spot for Rent Available Only for Residents of the Building Close To University',
        price: '150',
        img: 'https://plus.unsplash.com/premium_photo-1675756583871-6be3905c4ef4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
    },
    {
        name: 'Parking Close To University of Waterloo/Laurier Available!',
        description: 'K-W Property Management Corp Presents:   Located at 330 Phillip St ICON Waterloo! Parking Spot for Rent Available Only for Residents of the Building Close To University',
        price: '150',
        img: 'https://plus.unsplash.com/premium_photo-1675756583871-6be3905c4ef4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
    },
    {
        name: 'Parking Close To University of Waterloo/Laurier Available!',
        description: 'K-W Property Management Corp Presents:   Located at 330 Phillip St ICON Waterloo! Parking Spot for Rent Available Only for Residents of the Building Close To University',
        price: '150',
        img: 'https://plus.unsplash.com/premium_photo-1675756583871-6be3905c4ef4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'
    },
    
]