import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import './Tenent.css'


export const Tenent = () => {
    return (
        <>

            <KWNavbar />

            <div className="Tenent-container mt-5 pt-5 mb-5">
                <div className="mt-5 Tenent-row">
                    <div className="Tenent-column">
                        <div className="card tenent-card">
                            <div className="card-body">
                                <h4 className="card-body-header">Tenant Handbook</h4>
                                <div className="card-body-content">
                                    View details about the responsibilities of tenants, maintenance and safety tips, helpful information and much more.
                                </div>
                            </div>
                            <div className="card-footer">
                                <a href='/TenentHandbook' className='btn mx-auto my-1 card-footer-button'>View More</a>
                            </div>
                        </div>
                    </div>
                    <div className="Tenent-column">
                        <div className="card tenent-card">
                            <div className="card-body">
                                <h4 className="card-body-header">Work Order Request</h4>
                                <div className="card-body-content">
                                    Having issues with the condition of the apartment that you are renting (such as plumbing problems, no or low heat, a broken window)? Submit a work order request now.
                                </div>
                            </div>
                            <div className="card-footer">
                                <a href='/ServiceRequest' type="button" className='btn mx-auto my-1 card-footer-button'>Start Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="Tenent-column">
                        <div className="card col-12 tenent-card">
                            <div className="card-body">
                                <h4 className="card-body-header">Sublet Agreement</h4>
                                <div className="card-body-content">
                                    Looking to sublet your unit? Please download our sublet agreement linked below and submit it to our leasing department at
                                    <br />
                                    <a href="#" className='Sublet-link'>leasing@kwproperty.com</a>
                                </div>
                            </div>
                            <div className="card-footer">
                                <a href='#' className='btn mx-auto my-1 card-footer-button'>View More</a>
                            </div>
                        </div>
                    </div>
                    <div className="Tenent-column">
                        <div className="card tenent-card">
                            <div className="card-body">
                                <h4 className="card-body-header">Tenancy Terminations</h4>
                                <div className="card-body-content">
                                    Need to end your tenancy? Use our online Notice (N9) form to put in request to Terminate the Tenancy.
                                </div>
                            </div>
                            <div className="card-footer">
                                <a href='#' className='btn mx-auto my-1 card-footer-button'>Start Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*   landlords start   */}

            

            <KWFooter />

        </>
    )
}