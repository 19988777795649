import React from 'react';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import { Container, Row, Col } from 'react-bootstrap';

const Thankyou = () => {
  return (
    <>
      <KWNavbar />
      <Container className='py-5 mt-5'>
        <Row className='justify-content-center'>
          <Col md={6}>
            <h1 className='mt-5 text-center'>Thank you!</h1>
            <h5 className='mt-4 text-center'>
              <a href="https://www.kwproperty.com/Download/property_investors_checklist_v2.pdf">
                Click here to download your free guide Now!
              </a>
            </h5>
            <h6 className='mt-5 text-center'>Phone: 519-954-8082</h6>
            <h6 className='mt-3 text-center'>
              Email: <a href="mailto:kwp@kwproperty.com">kwp@kwproperty.com</a>
            </h6>
          </Col>
        </Row>
      </Container>
      <div className="fixed-bottom">
        <KWFooter />
      </div>
    </>
  );
};

export default Thankyou;
