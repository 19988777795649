import React from 'react';
import { Container, Row, Col, Form, Button, Table, Card } from 'react-bootstrap';
import KWAdminNavbar from '../../components/navbar/AdminNav';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from "react-icons/md";

const Title = () => {
    return (
        <>
            <KWAdminNavbar />
            <Container className='pt-5 col-10' fluid>
                <div className='form-content'>
                    <Card className='pt-3 border-0 form-card mt-4'>
                        <Row className="mt-4 p-2">
                            <Col md={8}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Color Code</th>
                                            <th>Link</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Example Title</td>
                                            <td>#FFFFFF</td>
                                            <td>https://example.com</td>
                                            <td className='d-flex justify-content-around'>
                                                <Button className='bg-info border-0'>
                                                    <FaEdit />
                                                </Button>
                                                <Button className='bg-danger border-0' color='red'>
                                                    <MdDeleteForever />
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col className='border rounded py-2' md={4}>
                                <h4 className='text-center'>Add Title</h4>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Link</Form.Label>
                                        <Form.Control type="text" placeholder="Enter title" />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Color Code</Form.Label>
                                        <Form.Control type="text" placeholder="Enter color code" />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control as="textarea" rows={3} placeholder="Enter title" />
                                    </Form.Group>

                                    <Button className='' variant="primary" type="submit">
                                        Save
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </div>

            </Container>
        </>
    );
};

export default Title;
