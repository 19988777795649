import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import KWNumber from '../numbers/number'

const KWNumberSection = () => {
  return (
    <Container className='mb-5'>
        <Row>
            <Col sm={12} lg={3}><KWNumber number={2500} name='Properties Managed' /> </Col>
            <Col sm={12} lg={3}><KWNumber number={20} name='Cities Managed' /> </Col>
            <Col sm={12} lg={3}><KWNumber number={20000} name='Properties Rented' /> </Col>
            <Col sm={12} lg={3}><KWNumber number={1000} name='Clients' /> </Col>
        </Row>
    </Container>
  )
}

export default KWNumberSection