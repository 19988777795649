import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import TWCard from '../card/card'
import { FaHome, FaHouseDamage } from 'react-icons/fa';

const KWServices = () => {
  return (
    <Container>
        <Row>
            {/* <Col sm={12} lg={4}>
                <h1>OUR <span className="blue">SERVICES</span></h1>
                <h4>QUALITY, TRUST, EASY</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non dignissimos in molestias quibusdam explicabo nulla porro? Accusamus vel ea, neque voluptates sed quae est illo impedit sapiente, vitae, eaque odit?</p>
                <Button variant='primary'>Consultation</Button>
            </Col> */}
            <Col sm={12} lg={12} className='mt-5 mt-lg-0'>
                <Row>
                    <Col sm={12} lg={6}>
                        <TWCard icon={<FaHouseDamage size={50} color='#044dac'/>} heading="Consulting and legal services" paragraph="Navigating the dynamic world of real estate requires expert guidance, and that's where our property and real estate consultancy & legal services shine. Backed by a seasoned team of professionals, we offer comprehensive insights, strategic advice, and tailored solutions to ensure your property transactions are seamless and successful. Whether you are buying, selling, or investing, our consultants leverage their in-depth market knowledge to provide a nuanced understanding of current trends, pricing dynamics, and potential opportunities. We pride ourselves on fostering long-term relationships, prioritizing client goals, and delivering results that exceed expectations. With our commitment to integrity, transparency, and personalized service, choosing us as your property and real estate consultant is an investment in your financial success and peace of mind. Let us be your trusted partner on the journey to real estate excellence."/>

                        <TWCard icon={<FaHome size={50} color='#044dac'/> }heading="Rentals" paragraph="Choose our rentals for an unparalleled living experience that seamlessly blends comfort, convenience, and quality. Our properties boast modern amenities, meticulously maintained interiors, and prime locations, ensuring a lifestyle that exceeds expectations. With a commitment to tenant satisfaction, we provide responsive maintenance services and transparent communication. Our rental options cater to diverse preferences and budgets, offering a range of well-designed spaces to call home. Embrace a living experience that prioritizes your needs and elevates your lifestyle. Choose our rentals for a place where you not only reside but truly belong."/>
                    </Col>
                    <Col sm={12} lg={6} className='mt-lg-5'>
                         <TWCard  icon={<FaHouseDamage size={50} color='#044dac'/>}heading="Tenants" paragraph="At KW Property Management, our unwavering commitment to tenant satisfaction is at the core of our property management philosophy. We prioritize creating a positive and comfortable living experience for our tenants in several ways. First and foremost, our responsive maintenance team is dedicated to addressing any issues promptly, ensuring that the properties are well-maintained and conducive to a high quality of life. We offer transparent communication channels, keeping tenants informed about property updates, events, and important information. Our user-friendly online platform facilitates easy rent payments, lease renewals, and maintenance requests. Additionally, we are active in the community and organize community events and foster a sense of belonging, creating a supportive environment for our tenants. Choosing KW Property Management   means choosing a property management partner that actively cares for and looks after the well-being of its tenants."/>

                        <TWCard  icon={<FaHome size={50} color='#044dac'/>}heading="Property Management & Leasing services" paragraph="Selecting KW Property Management for your property management and leasing needs ensures a top-tier service characterized by professionalism, efficiency, and a commitment to client satisfaction. With a proven track record in the industry, KW Property Management excels in providing comprehensive property management solutions tailored to your unique requirements. Our team of seasoned experts possesses an in-depth understanding of the real estate market, enabling us to maximize the value of your investment. We pride ourselves on our proactive approach, promptly addressing issues and implementing strategic leasing initiatives to minimize vacancies. With cutting-edge technology and transparent communication, we keep you informed and in control of your property's performance. Entrusting your property to KW Property Management means partnering with a trusted name dedicated to optimizing your property's potential and ensuring a seamless, worry-free experience for property owners and tenants alike."/>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
  )
}

export default KWServices