import React, { useState } from 'react'
import './navbar.css'
import logo from '../../assets/logo_blue.png'
import { Button, Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FaBars, FaChevronDown, FaMinus, FaPlus } from 'react-icons/fa';
const KWNavbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showOwnerLinks, setShowOwnerLinks] = useState(false);
  const [showTenantLinks, setShowTenantLinks] = useState(false);
  const [showAboutLinks, setShowAboutLinks] = useState(false);
  const [showApplyLinks, setShowApplyLinks] = useState(false);

  const toggleOwnerLinks = () => {
    setShowOwnerLinks(!showOwnerLinks);
    setShowTenantLinks(false);
    setShowAboutLinks(false);
    setShowApplyLinks(false);

  };

  const toggleTenantLinks = () => {
    setShowTenantLinks(!showTenantLinks);
    setShowAboutLinks(false);
    setShowOwnerLinks(false);
    setShowApplyLinks(false);
  };

  const toggleAboutLinks = () => {
    setShowAboutLinks(!showAboutLinks);
    setShowTenantLinks(false);
    setShowOwnerLinks(false);
    setShowApplyLinks(false);
  };

  const toggleApplyLinks = () => {
    setShowApplyLinks(!showApplyLinks);
    setShowTenantLinks(false);
    setShowOwnerLinks(false);
    setShowAboutLinks(false);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
      <Navbar bg="light" variant="light" className='fixed-top navbar-styles'>
        <Container>
          <Navbar.Brand>
            <Nav.Link as={Link} to="/">
              <img
                src={logo}
                width="135"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Nav.Link>
          </Navbar.Brand>
          <FaBars size={26} color='#222' onClick={toggleSidebar} className='ms-2 d-xl-none' />
          <Nav className='d-none d-xl-flex'>
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/browse">
              Browse
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/listings">
              Listings
            </Nav.Link>
            <Nav.Link as={Link} to="/properties">
              Properties
            </Nav.Link> */}
            <Nav.Link as={Link} to="/Showing">
              Showing
            </Nav.Link>
            {/* <a className='nav-link' href="https://www.kwproperty.com/calendar.asp">Showing</a> */}
            {/* <Nav.Link as={Link} to="/Tenent">
              Tenent
            </Nav.Link> */}
            {/* <Nav.Link as={Link} to="/Landlords">
              Landlords
            </Nav.Link> */}

            {/* <Nav.Link
                variant="link"
                className={`nav-link ${showPropertiesLinks ? 'highlight-link' : ''}`}
                onClick={togglePropertiesLinks}
                aria-controls="properties-links"
                aria-expanded={showPropertiesLinks}
              >
                Properties <span><FaChevronDown size={13} /></span>
              </Nav.Link> */}
            <Nav.Link
              variant="link"
              className={`nav-link ${showOwnerLinks ? 'highlight-link' : ''}`}
              onClick={toggleOwnerLinks}
              aria-controls="properties-links"
              aria-expanded={showOwnerLinks}>
              Owners <span><FaChevronDown size={13} /></span>
            </Nav.Link>
            <Nav.Link
              variant="link"
              className={`nav-link ${showTenantLinks ? 'highlight-link' : ''}`}
              onClick={toggleTenantLinks}
              aria-controls="properties-links"
              aria-expanded={showTenantLinks}
            >Tenants <span><FaChevronDown size={13} /></span></Nav.Link>
            <Nav.Link
             variant="link"
             className={`nav-link ${showAboutLinks ? 'highlight-link' : ''}`}
              as={Link} to="/team">
              About Us 
            </Nav.Link>
            {/* <Nav.Link
              variant="link"
              className={`nav-link ${showAboutLinks ? 'highlight-link' : ''}`}
              onClick={toggleAboutLinks}
              aria-controls="properties-links"
              aria-expanded={showAboutLinks}
            >About Us <span><FaChevronDown size={13} /></span></Nav.Link> */}
            <Nav.Link as={Link} to="/contactus">Contact Us</Nav.Link>
            {/* <Nav.Link
              variant="link"
              className={`nav-link ${showApplyLinks ? 'highlight-link' : ''}`}
              onClick={toggleApplyLinks}
              aria-controls="properties-links"
              aria-expanded={showApplyLinks}
            >Apply Online <span><FaChevronDown size={13} /></span></Nav.Link> */}

            {showOwnerLinks && (
              <div className="properties-links ps-4 p-2 d-flex justify-content-center bg-light drop-links">
                {/* <Nav.Link className="nav-link" as={Link} to="/PropertyManagement">
                  Property Management
                </Nav.Link>
                <Nav.Link className="nav-link" as={Link} to="/TenentPlacement">
                  Tenant Placement
                </Nav.Link>
                <Nav.Link className="nav-link" as={Link} to="/TenentScreening">
                  Tenant Screening
                </Nav.Link>
                <Nav.Link className="nav-link" as={Link} to="/Maintenance">
                  Maintenance
                </Nav.Link>
                <Nav.Link className="nav-link" as={Link} to="/LegalServices">
                  Legal Services
                </Nav.Link>
                <Nav.Link className="nav-link" as={Link} to="/CustomerReviews">
                  Owner Reviews
                </Nav.Link> */}
                <Nav.Link className="nav-link" as={Link} to="/Landlords">
                  More Info
                </Nav.Link>
                {/* <a className='nav-link' href="/Landlords">More Info</a> */}
              </div>
            )}

            {showTenantLinks && (
              <div className="properties-links ps-4 p-2 d-flex justify-content-center bg-light drop-links">
                {/* <Nav.Link className="nav-link" as={Link} to="/BecomeATenent">
                  Become a Tenant
                </Nav.Link> */}
                <Nav.Link className="nav-link" as={Link} to="/TenentHandbook">
                  Tenant Handbook
                </Nav.Link>
                <Nav.Link className="nav-link" as={Link} to="/SubletAgreement">
                  Sublet Agreement
                </Nav.Link>
                <Nav.Link className="nav-link" as={Link} to="/TenentTenancy">
                  Tenant Terminations
                </Nav.Link>
                {/* <Nav.Link className="nav-link" as={Link} to="/TenentReview">
                  Tenant Reviews
                </Nav.Link> */}
                <Nav.Link className="nav-link" as={Link} to="/ServiceRequest">
                  Submit a Work Order
                </Nav.Link>
                {/* <a className='nav-link' href="https://www.kwproperty.com/Tenants/ServiceRequest">Submit a Work Order</a> */}
              </div>
            )}

            {showAboutLinks && (
              <div className="properties-links ps-4 p-2 d-flex justify-content-center bg-light drop-links">
                <Nav.Link className="nav-link">
                  About KWP
                </Nav.Link>
                <Nav.Link className="nav-link" as={Link} to="/team">
                  Meet the team
                </Nav.Link>
                <Nav.Link className="nav-link">
                  Careers
                </Nav.Link>
                <Nav.Link className="nav-link">
                  FAQs
                </Nav.Link>
              </div>
            )}

            {showApplyLinks && (
              <div className="properties-links ps-4 p-2 d-flex justify-content-center bg-light drop-links">
                {/* <Nav.Link className="nav-link">
                  <Link style={{ textDecoration: "none" }} to={'/genaral-application'}>
                    General Application
                  </Link>
                </Nav.Link> */}
                {/* <a style={{ color: '#033e8a' }} target='_blank' className='nav-link' href="https://app.verifast.com/appv1/3000/KW-Property-Application/login">General Application</a> */}
                {/* <Nav.Link className="nav-link">
                  <Link style={{ textDecoration: "none" }} to={'/student-application'}>
                    Student Application
                  </Link>
                </Nav.Link> */}
                {/* <a style={{ color: '#033e8a' }} target='_blank' className='nav-link' href="https://app.verifast.com/appv1/3000/KW-Property-Application/login">Student Application</a> */}
              </div>
            )}
          </Nav>

        </Container>
      </Navbar>

      <Offcanvas show={showSidebar} onHide={toggleSidebar} placement="start" className="full-screen">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> <img
            src={logo}
            width="100"
            alt="React Bootstrap logo"
          /></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="container d-flex flex-column align-items-start">
            <Nav.Link as={Link} to="/" className="fs-5 ms-3">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/browse" className="fs-5 ms-3">
              Browse
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/properties" className="fs-5 ms-3">
              Properties
            </Nav.Link> */}
            <Nav.Link className="fs-5 ms-3" as={Link} to="/Showing">
              Showing
            </Nav.Link>
            {/* <a style={{color:'#033e8a', fontSize: '20px', marginLeft: '16px'}} className='nav-link' href="https://www.kwproperty.com/calendar.asp">Showing</a> */}
            {/* <Nav.Link className="fs-5 ms-3" as={Link} to="/student">Students</Nav.Link>
            <Nav.Link className="fs-5 ms-3" as={Link} to="/commercial">Commercial</Nav.Link> */}
            <Button
              variant="link"
              className="nav-link fs-5 d-flex align-items-center"
              onClick={toggleOwnerLinks}
              aria-controls="properties-links"
              aria-expanded={showOwnerLinks}
            ><span className='pe-1 pb-1'>{showOwnerLinks ? <FaMinus size={13} /> : <FaPlus size={13} />}</span>
              Owners
            </Button>
            {showOwnerLinks && (
              <div className="properties-links ps-4">
                {/* <Nav.Link className="nav-link fs-5" as={Link} to="/PropertyManagement">
                  Property Management
                </Nav.Link>
                <Nav.Link className="nav-link fs-5" as={Link} to="/TenentPlacement">
                  Tenant Placement
                </Nav.Link>
                <Nav.Link className="nav-link fs-5" as={Link} to="/TenentScreening">
                  Tenant Screening
                </Nav.Link>
                <Nav.Link className="nav-link fs-5" as={Link} to="/Maintenance">
                  Maintenance
                </Nav.Link>
                <Nav.Link className="nav-link fs-5" as={Link} to="/LegalServices">
                  Legal Services
                </Nav.Link>
                <Nav.Link className="nav-link fs-5" as={Link} to="/CustomerReviews">
                  Owner Reviews
                </Nav.Link> */}
                <Nav.Link className="nav-link fs-5" as={Link} to="/Landlords">
                  More Info
                </Nav.Link>
                {/* <a style={{fontSize:'20px'}} className='nav-link' href="https://www.kwproperty.com/Landlords">More Info</a> */}
              </div>
            )}
            <Button
              variant="link"
              className="nav-link fs-5 d-flex align-items-center"
              onClick={toggleTenantLinks}
              aria-controls="properties-links"
              aria-expanded={showTenantLinks}
            ><span className='pe-1 pb-1'>{showTenantLinks ? <FaMinus size={13} /> : <FaPlus size={13} />}</span>
              Tenants
            </Button>
            {showTenantLinks && (
              <div className="properties-link ps-4">
                {/* <Nav.Link className="nav-link fs-5" as={Link} to="/BecomeATenent">
                  Become a Tenant
                </Nav.Link> */}
                <Nav.Link className="nav-link fs-5" as={Link} to="/TenentHandbook">
                  Tenant Handbook
                </Nav.Link>
                <Nav.Link className="nav-link fs-5" as={Link} to="/SubletAgreement">
                  Sublet Agreement
                </Nav.Link>
                <Nav.Link className="nav-link fs-5" as={Link} to="/TenentTenancy">
                  Tenant Terminations
                </Nav.Link>
                {/* <Nav.Link className="nav-link fs-5" as={Link} to="/TenentReview">
                  Tenant Reviews
                </Nav.Link> */}
                <Nav.Link className="nav-link fs-5" as={Link} to="/ServiceRequest">
                  Submit a Work Order
                </Nav.Link>
                {/* <a style={{fontSize:'20px'}} className='nav-link' href="https://www.kwproperty.com/Tenants/ServiceRequest">Submit a Work Order</a> */}
              </div>
            )}

            {/* <Button
              variant="link"
              className="nav-link fs-5 d-flex align-items-center"
              onClick={toggleAboutLinks}
              aria-controls="properties-links"
              aria-expanded={showAboutLinks}
            ><span className='pe-1 pb-1'>{showAboutLinks ? <FaMinus size={13} /> : <FaPlus size={13} />}</span>
              About Us
            </Button> */}


            {showAboutLinks && (
              <div className="properties-link ps-4">
                <Nav.Link className="nav-link fs-5">
                  About KWP
                </Nav.Link>
                <Nav.Link className="nav-link fs-5" as={Link} to="/team">
                  Meet the team
                </Nav.Link>
                <Nav.Link className="nav-link fs-5">
                  Careers
                </Nav.Link>
                <Nav.Link className="nav-link fs-5">
                  FAQs
                </Nav.Link>
              </div>
            )}
            <Nav.Link as={Link} to="/team" className="fs-5 ms-3">
              About Us
            </Nav.Link>
            <Nav.Link as={Link} to="/contactus" className="fs-5 ms-3">
              Contact Us
            </Nav.Link>

            {/* <Button
              variant="link"
              className="nav-link fs-5 d-flex align-items-center"
              onClick={toggleApplyLinks}
              aria-controls="properties-links"
              aria-expanded={showApplyLinks}
            ><span className='pe-1 pb-1'>{showApplyLinks ? <FaMinus size={13} /> : <FaPlus size={13} />}</span>
              Apply Online
            </Button> */}
            {showApplyLinks && (
              <div className="properties-link ps-4">
                {/* <Nav.Link className="nav-link fs-5" as={Link} to={'/genaral-application'}>
                  General Application
                </Nav.Link> */}
                {/* <a style={{ fontSize: '20px' }} target='_blank' className='nav-link' href="https://app.verifast.com/appv1/3000/KW-Property-Application/login">General Application</a> */}
                {/* <Nav.Link className="nav-link fs-5" as={Link} to={'/student-application'}>
                  Student Application
                </Nav.Link> */}
                {/* <a style={{ fontSize: '20px' }} target='_blank' className='nav-link' href="https://app.verifast.com/appv1/3000/KW-Property-Application/login">Student Application</a> */}
              </div>
            )}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}


export default KWNavbar