import React, { useEffect, useState } from 'react'
import KWAdminNavbar from '../../components/navbar/AdminNav'
import "./style.css"
import { Col, Container, Row } from 'react-bootstrap'
import { RiListIndefinite } from "react-icons/ri";

const AdminHome = () => {
    const [totalListings, setTotalListings] = useState(0)
    const [totalProperties, setTotalProperties] = useState(0)
    useEffect(() => {
        // Define the API endpoint URL
        const apiUrl = process.env.REACT_APP_API_URL+'/v1/listings/kwpweb/listing';
        fetch(apiUrl)
            .then(response => {
                return response.json()
            })
            .then(responseData => {
                console.log(responseData)
                setTotalListings(responseData.length);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        fetch(`${process.env.REACT_APP_API_URL}/v1/property`)
            .then(response => {
                return response.json()
            })
            .then(responseData => {
                console.log(responseData)
                setTotalProperties(responseData.data.length);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [])
    return (
        <>
            <KWAdminNavbar />
            <div className='dash-board'>


                <Container>
                    <div className='my-2 fs-3'>Dashboard</div>
                    <Row className=" gap-3">
                        <Col className='rounded' xs={12} sm={6} md={4} lg={3}
                            style={{
                                background: '#EEEEEE',
                                height: '100px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px'
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <div className='fs-5'>Managed Properties</div>
                                </div>
                                <div className='fs-4 fw-bold text-center'>{totalProperties}</div>
                            </div>

                        </Col>
                        <Col className='rounded' xs={12} sm={6} md={4} lg={3}
                            style={{
                                background: '#EEEEEE',
                                height: '100px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px'
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <div className='fs-5'>Current Listing</div>
                                </div>
                                <div className='fs-4 fw-bold text-center'>{totalListings}</div>
                            </div>

                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    )
}

export default AdminHome
