import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import './TenentHandbook.css'
import pdf from '../../assets/KWPTenantHandbook.pdf'
// import { Collapse } from 'react-bootstrap'
// import React, { useState } from 'react'

import { Button } from 'react-bootstrap'


export const TenentHandbook = () => {

    // const [OfficeHours, setOfficeHours] = useState(false)

    // const [Contacts, setContacts] = useState(false)

    // const [MovingIn, setMovingIn] = useState(false)

    // const [Inspections, setInspections] = useState(false)

    // const [Keys, setKeys] = useState(false)

    // const handleCollapse = (from) => {

    //     if (from === 'OfficeHours') {
    //         setOfficeHours(!OfficeHours)
    //     }
    //     if (from === 'Contacts') {
    //         setContacts(!Contacts)
    //     }
    //     if (from === 'MovingIn') {
    //         setMovingIn(!MovingIn)
    //     }
    //     if (from === 'Inspections') {
    //         setInspections(!Inspections)
    //     }
    //     if (from === 'Keys') {
    //         setKeys(!Keys)
    //     }
    // }


    return (
        <>
            <KWNavbar></KWNavbar>
            <div className="container mt-5 pt-5">
                <div className="mt-5 mb-4">
                    <div className="tenent-header">Tenant Handbook</div>
                    <div className="pb-4">
                        {/* <a href={pdf} download>
                            <button className='float-end p-1 downloadbtn rounded bg-primary'>Dounload PDF</button>
                        </a> */}

                        <Button className='btn btn-primary float-end p-1 downloadbtn' href={pdf} download>Download PDF</Button>
                    </div>
                    <div className="mt-4 border rounded p-3">
                        <div
                            className='fs-4 justify-content-between d-flex'
                            style={{ cursor: "pointer" }}
                            data-bs-toggle="collapse"
                            data-bs-target="#OfficeHours"
                        >
                            <b>Office Hours</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className='collapse' id="OfficeHours">
                            <div className="tenent-para">
                                Monday – Friday <br />
                                9:00AM – 4:00PM <br />
                                541 Queen St S. Kitchener, ON N2G 1W9
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            style={{ cursor: "pointer" }}
                            data-bs-toggle="collapse"
                            data-bs-target="#Contacts"
                        >
                            <b>Contacts</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className='collapse' id="Contacts">
                            <div className='tenent-para'>
                                Emergencies – (519) 954-8082 option 1 <br />
                                General inquiries – (519) 954-8082 option 2 <br />
                                New/Current Landlords – (519) 954-8082 option 3 <br />
                                Work orders – (519) 954-8082 option 4 <br />
                                Tenant concerns – (519) 954-8082 option 5 <br />
                                kwp@kwproperty.com <br />
                                www.kwproperty.com
                            </div>
                        </div>
                    </div>

                    <div className="tenent-header mt-3">Useful Tenant Information</div>

                    <div className="border rounded p-3 mt-3">
                        <div
                            className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#MovingIn"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Moving In</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className='collapse' id="MovingIn">
                            <div className="pt-3">
                                <div className="tenent-para">
                                    Tenant insurance can protect you from a loss of your personal belongings, this is also a requirement to
                                    have during your tenancy as per your lease agreement. Please ensure your tenant/renters insurance is
                                    up to date.
                                </div>
                                <div className="tenent-para">
                                    If you are moving into a unit that is plus utilities (gas, water, and/or hydro) please ensure you have your
                                    account number(s) ready at the time of move in.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex' style={{ cursor: "pointer" }} data-bs-toggle="collapse" data-bs-target="#Inspections">
                            <b>Move-In Inspections</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div id="Inspections" className='collapse'>
                            <div className="tenent-para">
                                A move-in inspection is completed at the time of move in. The purpose of this inspection form is for us
                                to document any and all damage to the property that you do not want to be financially responsible for
                                on move-out. Once you have moved in, if you come across any deficiencies that were not noted at your
                                move in, please notify our office to ensure these issues are recorded.
                            </div>
                            <div className="tenent-para mt-3">When you move into your property, take the time to locate or know the following: </div>
                            <div className="tenent-li-div">
                                <li className='tenent-li-div-LI'>Breaker panel - To reset a breaker, move the switch all the way to its ‘off’ position, then back to
                                    ‘on’.
                                </li>
                                <li>
                                    Fuse panel - Look inside the fuse for charred glass or a broken filament — evidence of a blown
                                    fuse. Replace the blown fuse with one of the same type and amperage.
                                </li>
                                <li>
                                    GFCI outlets - To locate the outlet with the tripped GFCI, look for the one with a small button
                                    popped out. Remember, when a GFCI trips, it will trip any and all outlets connected to that GFCI.
                                    Most kitchen and bathrooms in new homes have multiple GFCI outlets, so it shouldn’t be too
                                    hard to find the GFCI that’s tripped in one of these rooms. Once you have located the outlet
                                    with the tripped GFCI, simply push the button back into place.
                                </li>
                                <li>
                                    Electric and/or gas meters – These are located on the exterior of the home, typically on either
                                    side of the building.
                                </li>
                                <li>
                                    Main water shut off valve - The shut off valve is typically located near the front foundation wall.
                                    The main water may have come through the concrete floor or through the wall. The valve is
                                    typically within 3-5 feet of where the main water enters. In some cases, the main water may
                                    enter in a different area, like a mechanical room, up through the floor, near the water heater or
                                    furnace.
                                </li>
                                <li>
                                    Outside water tap shut off - The home’s outdoor water shut-off valve is usually located near the
                                    ceiling, on the inside of the wall of the basement where the outdoor faucet is located. If the
                                    outdoor shut-off valve is tap or knob-style, turn it clockwise, or to the right, to shut it off. If it’s
                                    ball valve style, turn the lever to the right or in whichever direction the “off” arrow is pointing.
                                </li>
                                <li>
                                    Smoke and Carbon Monoxide Detectors – To test a smoke detector, click on the test button that
                                    is present on a smoke detector. Once the button is pressed, a few seconds will pass before the
                                    test starts, but you’ll know the test in process whenever you begin to hear a loud, piercing siren
                                    that comes via the smoke detector. To test a carbon monoxide detector, hold down the “test”
                                    button until you hear two beeps sound off. Once you hear these beeps, release your finger off
                                    the test button.
                                </li>
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#Keys"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Keys</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="Keys">
                            <div className="tenent-para">
                                If you lose your keys or lock yourself out of your home during business hours, you can visit our office to
                                obtain a key, please keep in mind there will be a $5.00 fee for a replacement key. If you lose a fob or
                                security key, fees will be higher.
                            </div>
                            <div className="tenent-para">
                                If you lose your keys or lock yourself out of your home after hours, there will be a $50.00 fee upon
                                arrival of a staff member. If you call a locksmith, you will be responsible to pay the fee determined by
                                the locksmith.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#RoutineMaintenance"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Routine Maintenance</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="RoutineMaintenance">
                            <div className="tenent-para">
                                As you settle into your new home, it is important to understand your responsibilities when it comes to
                                routine maintenance. These are some examples of routine maintenance that you will be responsible for:
                            </div>
                            <div className="tenent-li-div">
                                <li>Replacement of light bulbs</li>
                                <li>Cleaning out the lint trap on the dryer after each and every use</li>
                                <li>Cleaning and/or replacing the furnace filter every 3 months (if applicable)</li>
                                <li>Cleanliness of the unit and property</li>
                                <li>Removing and disposing of pet droppings promptly</li>
                                <li>Reporting any issues and repairs through the website</li>
                                <li>Ensuring the water softener is maintained and filled with salt regularly</li>
                                <li>Replacing furnace filters every 3 months, or as needed</li>
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#PayingRent"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Paying Rent</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="PayingRent">
                            <div className="tenent-para">
                                Rent is due on the 1st of each and every month, this includes weekends and holidays. You will be
                                supplied a TenantPay account number, which can be used to pay your rent by online banking. If you do
                                not yet have your TenantPay number, please email <a href="/Home">kwp@kwproperty.com.</a> If you prefer to pay by
                                cheque, these can be mailed or dropped off to our office.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#MovingOut"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Moving Out</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="MovingOut">
                            <div className="tenent-para">
                                If you are looking to move out of your current rental, it is required that our office receive written 60
                                days notice by the first of the month. Alternatively, you can us the N9 form which is available on the
                            </div>
                            <div className="tenent-para">
                                Ontario Landlord and Tenant Board website. Written 60 days notice or an N9 form can be emailed to <a href="/Home">kwp@kwproperty.com </a>
                                or dropped off at the office.
                            </div>
                            <div className="tenent-para">
                                If you are paying utilities directly, remember to contact your utility companies to let them know your
                                move-out date so they can prepare your final bill.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#OutExpectations"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Move Out Expectations</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="OutExpectations">
                            <div className="tenent-para">When you are ready to move, the following will be required to avoid any chargebacks:</div>
                            <div className="tenent-li-div">
                                <li>
                                    If you have trash that exceeds the normal pickup, you are to arrange to have it hauled away at
                                    your expense. Place all other trash within the appropriate trash receptacles for normal trash
                                    removal.
                                </li>
                                <li>
                                    Clean the interior and exterior of the property thoroughly. This includes cleaning vinyl or tile
                                    floors, wiping out drawers and shelves, appliances, sinks, toilets, bath tubs, showers, vanities,
                                    removal of cobwebs inside, etc. In general, the property is to be left in the same clean and wellmaintained condition as when you rented it. You are responsible for any above normal wear.
                                </li>
                                <li>
                                    The property is to be neatly mowed, trimmed, shovelled and/or salted. Remove all trash and
                                    debris. Ensure you pick up and dispose of any animal droppings.
                                </li>
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#OutInspections"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Move Out Inspections</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="OutInspections">
                            <div className="tenent-para">
                                Upon your move out, we will perform the move out inspection. Any damages caused to the rental unit
                                by willfully or the negligent act of tenants, guests or someone tenant permitted in the residential unit
                                will be listed in the move out inspection report. If damages are found to be caused by the tenant, you
                                may face charge backs to have these items repaired.
                            </div>
                            <div className="tenent-para">
                                Keys must be returned to our office once you have moved out, these must also be labelled properly. If
                                keys cannot be dropped off during office hours, they can be dropped off in the after hours drop off slot
                                located on the back door.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#RentReceipts"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Rent Receipts</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="RentReceipts">
                            <div className="tenent-para">
                                Rent receipts are issued out upon request. If you require a rent receipt, please email the office at <a href="/Home">kwp@kwproperty.com</a> and a rent receipt will be sent to you.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#Subletting/Assignments"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Subletting/Assignments</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="Subletting/Assignments">
                            <div className="tenent-para">
                                If you are looking to sublet or assign your unit, please ensure you are following the correct procedures
                                and rules outlined by the <i>Residential Tenancies Act, 2006.</i>
                            </div>
                            <div className="tenent-para">
                                The original tenant is responsible for all of the terms in the lease agreement and must collect the rent
                                from the subtenant, and ensure it is paid to the landlord on time and in full, on the first of each and
                                every month. If a tenant wants to sublet their unit, they require the landlord’s written approval. If a
                                tenant vacates the rental unit and gives possession of the rental unit to another person, but does not
                                obtain the consent of the landlord pursuant to section 97 of the RTA, there is no authorized sublet
                                pursuant to the RTA. The landlord may apply to the LTB under section 100 of the RTA for an order
                                terminating the tenancy and evicting the person who remained in the rental unit after the tenant
                                vacated, who is referred to as an “unauthorized occupant”. Tenants are not allowed to charge the
                                subtenant more rent than they pay to the landlord and cannot charge any fee, or key deposit, for the
                                new tenant to take over the lease. The original tenant is responsible for all unpaid rent and/or utilities,
                                damages caused by the subtenant, etc.
                            </div>
                            <div className="tenent-para">
                                You must ask permission from the landlord if you wish to sublet or to assign your unit. This must be in
                                writing and can be sent to kwp@kwproperty.com.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#Smoking"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Smoking</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="Smoking">
                            <div className="tenent-para">
                                For all tenants and visitors, smoking is not permitted inside any property or in any indoor common areas
                                of buildings, including hallways, stairwells, etc.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#NoiseComplaints"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Noise Complaints</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="NoiseComplaints">
                            <div className="tenent-para">
                                If you experience noise disturbances and or have complaints, please ensure you contact the city by-law office. If you have had to call the police during these instances, please notify our office of the occurrence number. If you are bothered by unreasonable noise, keep a written record of the time and nature of the disturbance. If the problem continues, put your concerns in writing to the office via email (note the date, time, description of the noise, occurrence number if applicable, unit number and names of people responsible for the disturbance).
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#ParkingIssues"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Parking Issues</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="ParkingIssues">
                            <div className="tenent-para">
                                You were assigned a parking space at the lease-signing. We record your license plate number and
                                vehicle information for monitoring purposes to help ensure your parking space is reserved for you each
                                time you return home. If you come home and notice that another vehicle is parked in your assigned
                                parking spot, please ensure you call city by-law to have the vehicle ticketed/towed if there is a private
                                5
                                parking sign at the property. You can also notify our office by email a photo of the vehicle and license
                                plate to <a href="/Home">kwp@kwproperty.com</a>
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#Garbage/Waste"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Garbage/Waste Collection</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="Garbage/Waste">
                            <div className="tenent-para">
                                Put all garbage in securely tied plastic bags (or in garbage bins) and put them out the night before pickup. After pickup, remove garbage containers, blue boxes and green bins promptly. Do not use your yard
                                as a garbage storage area. Large items in certain municipalities may require a “pick-up sticker”. It is your
                                responsibility to purchase these stickers.
                            </div>
                            <div className="tenent-para">
                                The Waste Whiz App can be downloaded from the Apple App Store, Google Store, etc. This app can be
                                used to notify you one when your garbage day is, as well as what will be collected on that day.
                                Reminders can also be set up on this app to ensure you never miss a collection day.
                            </div>
                            <div className="tenent-para">
                                We encourage you to participate in the recycling and green bin programs where available. If you require
                                additional blue boxes or green bins, you can contact your local municipality for details.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#LawnAndSnow"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Lawn and Snow Maintenance</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="LawnAndSnow">
                            <div className="tenent-para">
                                If you reside in a single family home, duplex, townhome, or semi detached property, you are responsible
                                for cutting the grass unless advised otherwise. As well, you are responsible during the winter for clearing
                                snow from the driveway, walks and public sidewalks in front of the home within 24 hours of snowfall.
                                Please remember to put salt down on steps, walkways, sidewalks and around your vehicles.
                            </div>
                            <div className="tenent-para">
                                In some cases, lawn maintenance and snow removal will be the responsibility of the landlord. In these
                                cases, a landscaping company will be hired to mow the lawn, plow the snow as well as salt. However, if
                                you have a car parked in the designated and/or parking area, it is impossible for the contractor to clear
                                that area. It is recommended that owners/tenants put down their own salt around their vehicles if
                                there is ice. If you require confirmation as to if you are responsible for these items, please email the
                                office.
                            </div>
                        </div>
                    </div>

                    <div className="tenent-header mt-3">Maintenance</div>

                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#SubmittingMaintenance"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Submitting Maintenance Requests</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="SubmittingMaintenance">
                            <div className="tenent-para">You can submit maintenance requests visiting <a href="/Home">www.kwproperty.com a</a> and clicking the “Tenants”.</div>
                            <div className="tenent-li-div">
                                <li>
                                    Please ensure you are specific in regards to what needs to be repaired. This will assist our
                                    contractors in pinpointing the issue.
                                </li>
                                <li>
                                    If you do not allow entry during a scheduled maintenance visit, you will be responsible for the
                                    service call.
                                </li>
                                <li>
                                    If it is found that the damage was caused by you, you will be responsible for the invoice
                                    associated with the repair.

                                </li>
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#PestControl"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Pest Control</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="PestControl">
                            <div className="tenent-para">
                                Make sure that food is stored in airtight containers and garbage is sealed and put out on a regular basis.
                                Do not feed birds, squirrels, stray animals, or wildlife. This could attract mice, rats or other pests. If you
                                require pest control services, please fill out a work order on the website by visiting <a href="/Home">www.kwproperty.com</a> and clicking the “Tenants” tab.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#LossofPower"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Loss of Power</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="LossofPower">
                            <div className="tenent-para">If electricity in a part of the house doesn’t work </div>
                            <div className="tenent-li-div">
                                <li>
                                    Reset the GFI outlet if applicable. These are usually located in the garage, patio, kitchen, or
                                    bathroom. Please see the GFI instruction on page 2.
                                </li>
                                <li>
                                    Check the circuit breaker box for a tripped breaker. Make sure you check ALL circuit breakers. A
                                    tripped circuit breaker is often difficult to see and it could appear that it is not tripped.
                                    Therefore, you must turn the breaker all the way off and then turned the breaker all the way on.
                                    If you do not turn the circuit breaker all the way off, it does not “reset” itself to correct the
                                    problem.
                                </li>
                            </div>
                            <div className="tenent-para">If the circuit breakers continually keep going off </div>
                            <div className="tenent-li-div">
                                <li>
                                    Check all appliances to see if too many appliances are running on the same circuit and causing
                                    an overload. In other words, do not plug in multiple electrical devices into one plug.
                                </li>
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#InEventof"
                            style={{ cursor: "pointer" }}
                        >
                            <b>In Event of Inclement Weather</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="InEventof">
                            <div className="tenent-li-div">
                                <li>
                                    Walk around the yard and note if there are any loose shingles, fence posts, gutters, shutters,
                                    window screens, hanging tree branches that need to be trimmed, etc. Notify our office by filling
                                    out a work order on the website.
                                </li>
                                <li>
                                    Make sure all windows and doors are securely closed. This also includes garage doors, shed
                                    doors, and gates if applicable. High winds plus heavy rain can equal unexpected water damage.
                                </li>
                                <li>
                                    In the event of a freeze, leave faucets dripping to reduce the risk of frozen pipes. Disconnect
                                    garden hoses from outside faucets and turn off the outside water tap from the inside.
                                </li>
                                <li>
                                    Please remember that severe weather may increase the maintenance/repair queue and it may
                                    take additional time to send someone out to address your issue. Please limit requests during
                                    inclement weather to serious safety issues and necessary emergency repairs to help with this.
                                </li>
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#OutsideWater"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Outside Water Taps</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="OutsideWater">
                            <div className="tenent-para">
                                If you live in a single-family home or have access to the basement, you have the convenience of an
                                outside water tap. In the winter, the cold could cause the pipe to freeze and burst so please turn off the
                                valve that is located in the basement and then open the outside tap. This will allow any water that is left
                                in the pipe to drain.
                            </div>
                            <div className="tenent-para">
                                Please see the outside water tap shut off instructions below
                            </div>
                            <div className="tenent-para">
                                The exterior water tap shut off should be located in the basement, near the wall where the tap is
                                located on the exterior. Your water tap shut off may be located in the ceiling above.
                            </div>
                            <div className="tenent-para">
                                If you have an unfinished basement, you will be able to see the blue shut off valve. Please turn this to
                                the right shut it off, and drain the tap from the outside until the line is dry.
                            </div>
                            <div className="tenent-para">
                                If you have a finished basement, there may be a white rectangular access hatch that needs to be
                                removed, this can be either located in the ceiling or wall. Once located, please turn the water shut off to
                                the right to shut it off, and drain the tap from the outside until the line is dry.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#HotWater"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Hot Water Tanks</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="HotWater">
                            <div className="tenent-para">
                                If you notice that there is no hot water coming from the tank or the tank is leaking, call the number on
                                the side of the tank for service. If there is no number, or the company states that the tank is not a rental,
                                please notify the office by filling out a work order.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#WaterRelated"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Water Related Issues</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="WaterRelated">
                            <div className="tenent-para">
                                If water is leaking from any appliance, fixture or pipe, close the main shut off to the property (typically
                                located in the basement).
                            </div>
                            <div className="tenent-para">
                                If the situation happens during regular business hours, please fill out a work order on the website. If it
                                occurs after hours, please ensure you call the emergency line.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#CloggedSinks"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Clogged Sinks, Toilets and Tubs</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="CloggedSinks">
                            <div className="tenent-para">
                                These service calls are a chargeback to the tenants if the pipes are full of debris. Please ensure you are
                                only flushing things down toilets sinks and tubs that belong in the pipes. Below is a short chart of
                                examples of things that do <u>not</u> belong in the pipes of the property.
                            </div>
                            <div className="tenent-table">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Wipes: disposable wipes, disinfecting wipes, baby wipes. This includes
                                                flushable wipes.
                                            </td>
                                            <td>Garbage</td>
                                        </tr>
                                        <tr>
                                            <td>Bandages and wraps</td>
                                            <td>Garbage</td>
                                        </tr>
                                        <tr>
                                            <td>Extinguished cigarettes</td>
                                            <td>Garbage</td>
                                        </tr>
                                        <tr>
                                            <td>Coffee grinds, egg shells and food</td>
                                            <td>Garbage</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Cotton balls, feminine hygiene products and swabs with a rolled paper
                                                or wooden rod
                                            </td>
                                            <td>Green bin</td>
                                        </tr>
                                        <tr>
                                            <td>Cotton swabs with a plastic rod</td>
                                            <td>Garbage</td>
                                        </tr>
                                        <tr>
                                            <td>Dental floss</td>
                                            <td>Garbage</td>
                                        </tr>
                                        <tr>
                                            <td>Disposable diapers and baby wipes</td>
                                            <td>Garbage</td>
                                        </tr>
                                        <tr>
                                            <td>Disposable toilet brushes and cleaning sponges</td>
                                            <td>Garbage</td>
                                        </tr>
                                        <tr>
                                            <td>Fat, oils and grease</td>
                                            <td>Green bin (limit of 1 cup) or garbage</td>
                                        </tr>
                                        <tr>
                                            <td>Hair</td>
                                            <td>Green bin</td>
                                        </tr>
                                        <tr>
                                            <td>Power towels and napkins</td>
                                            <td>Green bin</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#WaterOutages"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Water Outages</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="WaterOutages">
                            <div className="tenent-para">
                                Often times the city may turn off the water from the street if there is a broken watermain, or emergency
                                road work. If you notice that the water is off, and you have not received a notice from our office
                                advising you of this, please visit your City’s website for water disruption information and updates.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#WhatConstitutes"
                            style={{ cursor: "pointer" }}
                        >
                            <b>What Constitutes as an Emergency?</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="WhatConstitutes">
                            <div className="tenent-para">
                                An emergency can be an issue that is dangerous, hazardous, or if not addressed immediately could
                                cause substantial damage to the property or personal well-being. Examples are listed below. Please
                                note, some emergency situations may not be listed.
                            </div>
                            <div className="tenent-li-div">
                                <li>Flooding</li>
                                <li>Lack of heat from September 15 - June 1 (minimum 21C)</li>
                                <li> Frozen and/or burst pipes</li>
                                <li> Gas leaks</li>
                                <li>Loss of electricity</li>
                                <li>Sewage back ups</li>
                                <li> Lock outs </li>
                            </div>
                            <div className="tenent-para">
                                Emergencies are <b><u>NOT</u></b> an annoying sound, air conditioning failure, appliance malfunction or something
                                similar. While these situations may be inconvenient, they are not considered emergencies. Please visit
                                our website and fill out a work order, the maintenance team will ensure the matter is dealt with within
                                next 24-48 hours.
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#EmergencyProcedures"
                            style={{ cursor: "pointer" }}
                        >
                            <b>Emergency Procedures</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="EmergencyProcedures">
                            <div className="tenent-para">
                                In case of fire, medical emergency or a situation that could cause immediate peril to yourself or others,
                                please call 911 immediately. If 911 has been called, please notify us by calling the emergency line.
                            </div>
                            <div className="tenent-para">
                                In the event of any emergency, if lives are threatened, or when substantial damage could be done to the
                                property, we are not required to provide any notice before entering.
                            </div>
                            <div className="tenent-para">A troubleshooting guide is listed below:</div>
                            <div className="tenent-li-div">
                                <li>Clogged Sinks - This service call is a charge to the tenants if the pipes are full of debris. The
                                    tenant will not be charged if the pipe is structurally compromised </li>
                                <li>No Hydro - The tenant will be charged for this service call if the breakers are off or fuses are
                                    burnt out. The tenant will also be charged for this service call if the receptacle reset button
                                    9
                                    needs to be pressed to reset. Please ensure you have checked breakers, fuses and reset buttons
                                    prior to our company sending out an electrician. This will avoid costs to you.
                                </li>
                                <li>Furnace Not Working - Please make sure the outside vent (exhaust vent) is not clogged by debris
                                    or ice or snow. If the HVAC technician arrives on site and determines the cause to be a blocked
                                    vent, the tenant will be held responsible for the service call. The tenant will also be held
                                    responsible for the service call if the batteries on the thermostat are low, or a dirty furnace
                                    prevented the furnace from working (Furnace filters should be changed at least every 3
                                    months). Make sure Furnace and/or AC switch is turned on, or the tenant will be held
                                    responsible for the service call.
                                </li>
                                <li>Light Bulbs - The tenant is responsible for changing all burnt out light bulbs</li>
                                <li>No Access Granted - Please be sure if you request to be home during the service call you are in
                                    fact there. If you are not there to grant access after you specifically ask for this service, you will
                                    be charged for the service call.
                                </li>
                                <li>
                                    Water Softener - If your work order is in regards to your water softener running continuously,
                                    please put the water softener on bypass by either turning the valve manually or on the program
                                    screen selecting bypass. If you cannot determine the bypass please call our office immediately. If
                                    your water has stopped working, your softener may have internally “imploded” if it has not
                                    been maintained. Please put the softener on bypass, and test the water. Call our office
                                    immediately if this issue occurs.
                                </li>
                            </div>
                        </div>
                    </div>
                    <div className="border rounded p-3 mt-3">
                        <div className='fs-4 justify-content-between d-flex'
                            data-bs-toggle="collapse"
                            data-bs-target="#NoHeatCalls"
                            style={{ cursor: "pointer" }}
                        >
                            <b>No Heat Calls</b>
                            <div className="">
                                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
                            </div>
                        </div>
                        <div className="collapse" id="NoHeatCalls">
                            <div className="tenent-para">
                                Prior to calling the emergency line or filling out a work order for a no heat call, please ensure you do the
                                following
                            </div>
                            <div className="tenent-li-div">
                                <li>Make sure the outside vent (exhaust vent) is not clogged by debris, ice or snow.</li>
                                <li> Make sure the furnace and/or AC switch is in the ON position. </li>
                                <li> Ensure your furnace filter has been replaced and is not dirty/clogged.</li>
                            </div>
                            <div className="tenent-para">
                                If the HVAC technician arrives on site and determines the cause to be a blocked vent, low thermostat
                                battery, or dirty furnace filter, the tenant will be held responsible for the service call.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}

            <KWFooter></KWFooter>
        </>
    )
}