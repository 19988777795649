import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';


export const Maintenance = () => {
    return (
        <>
        <KWNavbar></KWNavbar>
        <div className="container mt-5 mb-5 pt-5">
            <div className="mt-5 text-center h1">Maintenance</div>
        </div>
        <KWFooter></KWFooter>
        </>
    )
}