import React from 'react'
import KWFooter from '../../components/footer/footer'
import { Outlet } from 'react-router-dom'

const Admin = () => {

    return (
        <>
            <Outlet />
            <KWFooter />
        </>
    )
}

export default Admin
