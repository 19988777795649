import React from 'react'
import './filters.css'
import { Button, Col, Row } from 'react-bootstrap'

import { useEffect, useState } from 'react';



// import { Listings } from '../../components/listings/Listings'
// import { LineWave } from 'react-loader-spinner';

export const Filters = () => {

  const [filterData, setFilterData] = useState();
  const [propertyList, setPropertyList] = useState(null);
  const [filteredProperty, setFilteredProperty] = useState(null);
  const [unitType, setUnitType] = useState('');
  const [priceRange, setPriceRange] = useState('');
  const [city, setCity] = useState('');
  const [bedroomCount, setBedroomCount] = useState('');


  const [checkedItems, setCheckedItems] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});

  const handleCheckboxChange = (data) => {
    setCheckboxStates((prevCheckboxStates) => ({
      ...prevCheckboxStates,
      [data]: !prevCheckboxStates[data],
    }));

    setCheckedItems((prevCheckedItems) =>
      checkboxStates[data]
        ? prevCheckedItems.filter((item) => item !== data)
        : [...prevCheckedItems, data]
    );
  }

  useEffect(() => {

    const apiUrl = process.env.REACT_APP_API_URL + '/v1/rentals/property/filters';

    fetch(apiUrl, {
      method: 'GET'
    }).then(response => response.json())
      .then(responseData => {
        setFilterData(responseData)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

  });

  useEffect(() => {
    // Define the API endpoint URL
    const apiUrl = process.env.REACT_APP_API_URL + '/v1/rentals/units/listings';

    // Make the API request
    fetch(apiUrl, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(responseData => {
        // Store the fetched data in the 'data' state
        setPropertyList(responseData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const onSubmit = () => {

    let filterConditions = []

    if (unitType) {
      if (unitType !== 'A') {
        const condition = item => item.Unit.propertyType === unitType;
        filterConditions.push(condition);
      }
    }

    if (priceRange) {
      if (priceRange !== 'A') {

        // Split the range string into two parts using the '-' delimiter
        const [startString, endString] = priceRange.split('-');

        // Convert the string parts to integers
        const minRent = parseInt(startString, 10);
        if (endString === '+') {
          // const condition = item => item.Unit.MarketRent >= minRent;
          const condition = item => item.Unit.Rent >= minRent;
          filterConditions.push(condition);
        } else {
          const maxRent = parseInt(endString, 10);
          // const condition = item => item.Unit.MarketRent >= minRent && item.Unit.MarketRent <= maxRent;
          const condition = item => item.Rent >= minRent && item.Rent <= maxRent;
          filterConditions.push(condition);
        }
      }
    }

    if (city) {
      if (city !== 'A') {
        const condition = item => item.Unit.Address.City === city;
        filterConditions.push(condition);
      }
    }

    if (bedroomCount) {
      if (bedroomCount !== 'A') {

        let bedroomCountArray = []

        if (bedroomCount === 'OneBed') {
          bedroomCountArray.push('OneBed')
        } else if (bedroomCount === 'TwoBed') {
          bedroomCountArray.push('TwoBed')
        } else if (bedroomCount === 'ThreeBed') {
          bedroomCountArray.push('ThreeBed')
        } else if (bedroomCount === '3 or more') {
          bedroomCountArray.push('ThreeBed')
          bedroomCountArray.push('FourBed')
        } else if (bedroomCount === '4 or more') {
          // bedroomCount.push('ThreeBed')
          bedroomCountArray.push('FourBed')
        } else {
          bedroomCountArray = []
        }

        if (bedroomCountArray && bedroomCountArray.length > 0) {
          const condition = item => bedroomCountArray.includes(item.Unit.UnitBedrooms);
          filterConditions.push(condition);
        }

      }
    }

    if (checkedItems && checkedItems.length > 0) {
      const condition = item => checkedItems.every(feature => item.Unit.Features.includes(feature));
      filterConditions.push(condition);
    }

    const filteredPropertyList = propertyList.filter(item => {
      return filterConditions.every(condition => condition(item));
    });

    setFilteredProperty(filteredPropertyList);

  }

  return (

    <div className='background p-5'>
      <Row>
        <Col sm={12} lg={6}>

          <select className='required-number required-number2 w-100'
            value={unitType}
            onChange={(e) => setUnitType(e.target.value)}
            name="unitType"
            id="UnitType">
            <option value="A">All Unit Types</option>
            {

              filterData ? (

                filterData?.propertyTypes.length > 0 ? (

                  filterData?.propertyTypes.map((data) => {
                    return (
                      <>
                        <option value={data.code}>{data.type}</option>
                      </>
                    )
                  })
                ) : ''
              ) : ''
            }
          </select>

          <select className='required-number required-number2 w-100 mt-3'
            value={priceRange}
            onChange={(e) => setPriceRange(e.target.value)}
            name="priceRange"
            id="PriceRange">
            <option value="A">All Price Range</option>
            {

              filterData ? (

                filterData?.priceRange.length > 0 ? (

                  filterData?.priceRange.map((data) => {
                    return (
                      <>
                        <option value={`${data.minPrice}-${data.maxPrice}`}>
                          {`${data.maxPrice}` === '+' ? `${data.minPrice}${data.maxPrice}` : `${data.minPrice}-${data.maxPrice}`}
                        </option>
                      </>
                    )
                  })
                ) : ''
              ) : ''
            }
          </select>

          <select className='required-number required-number2 w-100 mt-3'
            value={city}
            onChange={(e) => setCity(e.target.value)}
            name="city"
            id="City">
            <option value="A">Select City</option>
            {

              filterData ? (

                filterData?.cities.length > 0 ? (

                  filterData?.cities.map((data) => {
                    return (
                      <>
                        <option value={data}>
                          {data}
                        </option>
                      </>
                    )
                  })
                ) : ''
              ) : ''
            }
          </select>
          <select className='required-number required-number2 w-100 mt-3'
            value={bedroomCount}
            onChange={(e) => setBedroomCount(e.target.value)}
            name="bedroomCount"
            id="BedroomCount">
            <option value="A"># of Bedrooms</option>
            {

              filterData ? (

                filterData?.numberOfBedRooms.length > 0 ? (

                  filterData?.numberOfBedRooms.map((data) => {
                    return (
                      <>
                        <option value={data.code}>
                          {data.type}
                        </option>
                      </>
                    )
                  })
                ) : ''
              ) : ''
            }
          </select>

        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <Col sm={12} lg={6}>
              {filterData?.features && filterData.features.length > 0 ? (
                filterData.features.map((data) => (
                  <label key={data}>
                    <input
                      type="checkbox"
                      checked={checkboxStates[data] || false}
                      onChange={() => handleCheckboxChange(data)}
                      className='my-2 mt-3'
                      value={data}
                    />
                    {data}
                  </label>
                ))
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
      <Button onClick={onSubmit} className='btn btn-primary search-button'>Search</Button>

    </div>
  )
}
