import React from 'react';

const PropertyDetailsSection = ({ isTenantExist }) => (
    <div style={{ alignItems: "center" }} className='d-flex'>
        <h4>Property Address:</h4>
        <div className='px-2'>{isTenantExist?.PropertyAddress}</div>
    </div>
);

export default PropertyDetailsSection;
