import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './video.css'

const KWVideo = () => {
  return (
    <Container>
        <Row>
            {/* <Col sm={12} lg={6} className='py-5 video-item'>
            <div className="video"><iframe width="100%" height="355"src="https://www.youtube.com/embed/D0UnqGm_miA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>
            </Col> */}
            <Col sm={12} lg={6} className='d-none d-lg-flex flex-column align-items-start py-5 justify-content-start video-item'>
                <h1>OUR <span className="blue">SERVICES</span></h1>
                <h4>QUALITY, TRUST, EASY</h4>
                <p>We are professionals in property management, handling day-to-day operations, addressing issues promptly, and utilizing technology to streamline all processes. Contact us now to learn more!</p>
                {/* <Button variant='primary'>Consultation</Button> */}
            </Col>
            <Col sm={12} lg={6} className='d-none d-lg-flex flex-column align-items-start py-5 justify-content-center video-item'>
            <h1>Become our <span className='blue fw-light'>Tenant</span></h1>
            <p className='pe-lg-5'>Becoming a tenant with us is a straightforward and rewarding process. Start by exploring our diverse range of well-maintained properties that cater to various preferences and lifestyles. Once you've identified the perfect space, our user-friendly online platform allows for easy application submission. Our tenant-centric approach prioritizes your comfort and satisfaction. We offer competitive rental rates, transparent lease agreements, and prompt, reliable maintenance services. With a commitment to fostering a sense of community, our properties are designed to provide a welcoming and secure environment. Choose us as your preferred property manager, and experience the difference of leasing with a company dedicated to your well-being and convenience. Welcome home!</p>
            <a href="/BecomeATenent" className='btn btn-primary'>Become A Tenent</a>
            </Col>
        </Row>
    </Container>
  )
}

export default KWVideo