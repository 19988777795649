import React from 'react';
import { LineWave } from 'react-loader-spinner';

const EmailVerificationSection = ({ email, setEmail, isTenantExist, isLoading, verifyTenantEmail,handleKeyPress }) => (
    <div className='w100Icenter my-5'>
        <div>
            <div style={{ width: "300px" }}>
                <input
                    onKeyDown={handleKeyPress}
                    placeholder='Enter Your Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className='form-control w-100'
                    type="text"
                />
            </div>
            <div style={{ width: "300px" }}>
                <input
                    value={!isTenantExist ? 'Verify Your Email' : 'Email Verified'}
                    type="button"
                    onClick={verifyTenantEmail}
                    className={`w-100 my-2 btn ${isTenantExist ? 'btn-success' : 'btn-info'}`}
                />
            </div>
            <div>
                {isLoading && (
                    <LineWave
                        height="200px"
                        width="300px"
                        color="#0586f0"
                        ariaLabel="line-wave"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                )}
            </div>
        </div>
    </div>
);

export default EmailVerificationSection;
