import React from 'react';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import { Container, Row, Col } from 'react-bootstrap';
import './thankYou.css'

const ThankYou = () => {
    return (
        <>
            <KWNavbar />
            <div className="thankyou_container">
                <div className="fs-2 thankyou_heading">
                    THANK YOU <br />
                    FOR CONTACTING US!
                </div>
                <div className="mt-3 recive_text">
                    We have received your information. We will review shortly and respond if necessary. Thank you for visiting and return often to see all our new properties!
                </div>
                <div className="thankyou_phoneE">
                    <div>Phone: 519-954-8082</div>
                    <div>Email: <a href="#">kwp@kwproperty.com</a></div>
                </div>
                <div className="thankyou_bgBox">
                    If this is an after hours emergency such as flood, fire or heating problems, please call our office at 519-954-8082 to be connected to the emergency after hours line.
                </div>
            </div>
            <div className="">
                <KWFooter />
            </div>
        </>
    );
};

export default ThankYou;
