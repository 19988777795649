import React from 'react';
import { Form } from 'react-bootstrap';

const TermsAndConditionsSection = ({ isTermsAndConditionsChecked, setTermsAndConditionsChecked }) => (
    <div className="border rounded p-3 mt-3">
        <div className='fs-4 justify-content-between d-flex' data-bs-toggle="collapse" data-bs-target="#TermsandConditions" style={{ cursor: "pointer" }}>
            <div><b className='service-form-para2-H'>Terms and Conditions</b></div>
            <div className="">
                <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="" />
            </div>
        </div>
        <div className="collapse" id="TermsandConditions">
            <div className="service-form-para2">
                <div className="service-form-para2-text">
                    Tenants please be advised you will be charged for a service call if the following work order is a result of tenant neglect or damage. Also including below but not limited to:
                </div>
                {/* List other terms as per original UI */}
            </div>
        </div>
        <div className="mt-2 ps-2">
            <div className='mb-3'>
                <div className='service-form-label2'>I accept these terms and conditions:</div>
                <Form>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={isTermsAndConditionsChecked}
                        onChange={() => setTermsAndConditionsChecked(!isTermsAndConditionsChecked)}
                    />
                </Form>
            </div>
        </div>
    </div>
);

export default TermsAndConditionsSection;
