import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer'; 


export const PropertyManagement = () => {
    return (
        <>
        <KWNavbar></KWNavbar>
        <div className="container mt-5 pt-5 mb-5">
            <div className="h1 mt-5 text-center">Property Management</div>
        </div>
        <KWFooter></KWFooter>
        </>
    )
}