import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer'; 


export const TenentReview = () => {
    return (
        <>
        <KWNavbar></KWNavbar>
        <div className="container mt-5 mb-5 pt-5">
            <div className="text-center h1 mt-5">Tenant Review</div>
        </div>
        <KWFooter></KWFooter>
        </>
    )
}