import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import ReCAPTCHA from 'react-google-recaptcha';
import './ConfirmProperty.css';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import swal from 'sweetalert';
import Moment from 'react-moment';
import 'moment-timezone';
Moment.globalTimezone = 'America/Toronto';

export const ConfirmProperty = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [captcha, setCaptcha] = useState(false);
    const location = useLocation();
    const handleCaptcha = (val) => {
        val ? setCaptcha(true) : setCaptcha(false);
    }
    const bookData = location.state.bookData;
    // const labl = new Date(location.state.bookData.time);
    const [bookingData, setBookingData] = useState({
        eventId: location.state.bookData.eventId,
        recipientEmail: email,
        // cc: config.emails.booking.cc, // ITV changes
        recipientName: name,
        phone: mobile,
        // mailSubject: config.emails.booking.subject, // ITV changes
        templateData: {
            message: "To cancel the booking please call to 0000000000 or you can also cancel the booking by clicking ",
            link1: "<link>"
        }
    });
    useEffect(() => {
        // console.log(location.state.bookData);
        setBookingData({
            eventId: location.state.bookData.eventId,
            recipientEmail: email,
            // cc: config.emails.booking.cc, // ITV changes
            recipientName: name,
            phone: mobile,
            // mailSubject: config.emails.booking.subject, // ITV changes
            templateData: {
                message: "To cancel the booking please call to 0000000000 or you can also cancel the booking by clicking ",
                link1: "<link>"
            }
        })
        // console.log(window.history)
    }, [name, email, mobile])


    function validateEmail(email) {
        // Regular expression for basic email validation
        var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }


    const onSubmit = () => {
        if (name === '') {
            swal("!", "Name is require!", "warning");
        } else if (mobile === '') {
            swal("!", "Mobile is require!", "warning");
        } else if (email === '') {
            swal("!", "Email is require!", "warning");
        } else {

            if (!validateEmail(email)) {
                console.log("Email is invalid");
                swal("!", "Invalid EmailId!", "warning");
                return;
            }

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(bookingData);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_API_URL + "/v1/showing/book", requestOptions)
                .then(response => response.json())
                .then((result) => {
                    // console.log(result)
                    if (result.status === 200) {
                        swal(result.res, result.res, "success");
                        setName('')
                        setEmail('')
                        setMobile('')
                    }
                })
                .catch(error => console.log('error', error));
        }
    }
    // console.log(location.state)
    return (
        <>
            <KWNavbar></KWNavbar>

            <div className='confirm-property-main mt-5 pt-5'>
                <div className='confirm-property-head mt-5'>Want to see this property?</div>
                <div className='confirm-property-para'>To confirm your attendance to view <strong>{bookData.summery}</strong> please fill out the form below and click 'Confirm'. A KWP Management Corp representative will be at the property for 15 minutes at that time listed below. Don't be late!</div>
                <div className='confirm-property-griddiv'>
                    <div className='confirm-property-grid'>
                        <div className='confirm-property-LgridT'>Property</div>
                        <div className='confirm-property-RgridT'><strong>{bookData.summery}</strong></div>
                    </div>
                    <div className='confirm-property-grid'>
                        <div className='confirm-property-LgridT'>Showing</div>
                        <div className='confirm-property-RgridT'>
                            <strong>{bookData.title} @
                                <Moment date={location.state.bookData.time} format="hh:mm a" />
                            </strong></div>
                    </div>
                    <div className='confirm-property-grid'>
                        <div className='confirm-property-LgridT'>Your Name*</div>
                        <div className='confirm-property-RgridT'>
                            <input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                name="name"
                                id="name"
                                className=' required-number required-number2 form-control' />
                        </div>
                    </div>
                    <div className='confirm-property-grid'>
                        <div className='confirm-property-LgridT'>Phone*</div>
                        <div className='confirm-property-RgridT'>
                            <input
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                type="text"
                                name="number"
                                id="number"
                                className=' required-number required-number2 form-control' />
                        </div>
                    </div>
                    <div className='confirm-property-grid'>
                        <div className='confirm-property-LgridT'>Email Address*</div>
                        <div className='confirm-property-RgridT'>
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                name="email"
                                id="email"
                                className=' required-number required-number2 form-control' />
                        </div>
                    </div>
                    <div className='confirm-property-grid'>
                        <div className='confirm-property-LgridT' style={{ opacity: 0 }}>comment</div>
                        <div className='confirm-property-RgridText'>
                            <div className='confirm-property-RgridSubT'>
                                To protect this website from spam, please check the box below.
                            </div>
                            <div>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                    onChange={handleCaptcha}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='confirm-property-grid'>
                        <div className='confirm-property-LgridT' style={{ opacity: 0 }}>comment</div>
                        <div className='confirm-property-gridBTN'>
                            <button
                                onClick={() => window.history.back()}
                                type='button' className='btn btn-secondary CPgridButton1'>Back</button>
                            {
                                captcha ?
                                    <button
                                        onClick={onSubmit}
                                        type='button'
                                        className='btn btn-primary ms-1 CPgridButton2'>Confirm</button>
                                    : <button
                                        onClick={() => swal("!", "Please check the captcha", "warning")}
                                        type='button'
                                        className='btn btn-primary ms-1 CPgridButton2'>Confirm</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <KWFooter></KWFooter>
        </>
    )
}
